import React, { lazy, Suspense } from 'react';
import Loading from './common/loading/Loading';
import Layout from './layout';

const Login = lazy(() => import('./login/Login'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
//Sale Components
const NewSale = lazy(() => import('./sale/NewSale'));
const Quotations = lazy(() => import('./sale/Quotations'));
const ConfirmSale = lazy(() => import('./sale/ConfirmSale'));
//Product Components
const Product = lazy(() => import('./product/Product'));
const NewProduct = lazy(() => import('./product/NewProduct'));
const Labels = lazy(() => import('./product/Labels'));
//Accounting Components
const Accounting = lazy(() => import('./accounting/Accounting'));
const AccountMovement = lazy(() => import('./accounting/AccountMovement'));
//Counting Components
const Counting = lazy(() => import('./counting/Counting'));
const NewCounting = lazy(() => import('./counting/NewCounting'));
//Product Quotation Components
const ProductQuotation = lazy(
  () => import('./productQuotation/ProductQuotation')
);
const NewProductQuotation = lazy(
  () => import('./productQuotation/NewProductQuotation')
);
//Inventory Components
const Movement = lazy(() => import('./inventory/Movement'));
//Report Components
const SalesHistory = lazy(() => import('./report/SalesHistory'));
const InventoryMovementHistory = lazy(
  () => import('./report/InventoryMovementHistory')
);
const InventoryInfoV1 = lazy(() => import('./report/InventoryInfoV1'));
const InventoryInfoV2 = lazy(() => import('./report/InventoryInfoV2'));
const InventoryReorderReport = lazy(
  () => import('./report/InventoryReorderReport')
);
const NewProductPricing = lazy(() => import('./report/NewProductPricing'));
const SaleConsignment = lazy(() => import('./report/SaleConsignment'));
const PaymentConsigment = lazy(() => import('./report/PaymentConsigment'));
const SalesReport = lazy(() => import('./report/SalesReport'));
const BalanceResult = lazy(() => import('./report/BalanceResult'));
//ML Components
const ExcelSale = lazy(() => import('./mercadoLibre/ExcelSale'));
const SyncSales = lazy(() => import('./mercadoLibre/SyncSales'));
const SyncProducts = lazy(() => import('./mercadoLibre/SyncProducts'));
const MlProducts = lazy(() => import('./mercadoLibre/MlProducts'));
const MlFullProducts = lazy(() => import('./mercadoLibre/MlFullProducts'));
const StockFullSave = lazy(() => import('./mercadoLibre/StockFullSave'));
const StockFullGet = lazy(() => import('./mercadoLibre/StockFullGet'));
const StockWithPenaltiesSave = lazy(
  () => import('./mercadoLibre/StockWithPenaltiesSave')
);
const StockWithPenaltiesGet = lazy(
  () => import('./mercadoLibre/StockWithPenaltiesGet')
);
const MlStockFullRequestGet = lazy(
  () => import('./mercadoLibre/MlStockFullRequestGet')
);
//TiendaNube Components
const UploadProductsPrices = lazy(
  () => import('./tiendaNube/UploadProductsPrices')
);
//Excel Updates Components
const ExcelUploadProducts = lazy(() => import('./excel/ExcelUploadProducts'));
//Configuration Components
const Family = lazy(() => import('./configuration/Family'));
const FamilyCreation = lazy(() => import('./configuration/FamilyCreation'));
const Subfamily = lazy(() => import('./configuration/Subfamily'));
const SubfamilyCreation = lazy(
  () => import('./configuration/SubfamilyCreation')
);
const Supplier = lazy(() => import('./configuration/Supplier'));
const SupplierCreation = lazy(() => import('./configuration/SupplierCreation'));
const Brand = lazy(() => import('./configuration/Brand'));
const BrandCreation = lazy(() => import('./configuration/BrandCreation'));
const Customer = lazy(() => import('./configuration/Customer'));
const CustomerCreation = lazy(() => import('./configuration/CustomerCreation'));
const PriceType = lazy(() => import('./configuration/PriceType'));
const PriceTypeCreation = lazy(
  () => import('./configuration/PriceTypeCreation')
);
const Branch = lazy(() => import('./configuration/Branch'));
const BranchCreation = lazy(() => import('./configuration/BranchCreation'));
const User = lazy(() => import('./configuration/User'));
const UserCreation = lazy(() => import('./configuration/UserCreation'));
const Platform = lazy(() => import('./configuration/Platform'));

const loadComponent = (component) => {
  return <Suspense fallback={<Loading />}>{component}</Suspense>;
};

export const routes = [
  {
    path: '/',
    Component: Layout,
    children: [
      {
        index: true,
        path: 'dashboard',
        element: loadComponent(<Dashboard />),
      },
      {
        index: true,
        path: 'login',
        element: loadComponent(<Login />),
      },
      {
        path: 'sale/:page?/:id?',
        children: [
          {
            index: true,
            path: 'newSale',
            element: loadComponent(<NewSale />),
          },
          {
            index: true,
            path: 'editSale/:id',
            element: loadComponent(<NewSale />),
          },
          {
            index: true,
            path: 'quotations',
            element: loadComponent(<Quotations />),
          },
          {
            index: true,
            path: 'confirmSale/:id',
            element: loadComponent(<ConfirmSale />),
          },
        ],
      },
      {
        path: 'product/:page?/:id?',
        children: [
          {
            index: true,
            path: 'landing',
            element: loadComponent(<Product />),
          },
          {
            index: true,
            path: 'labels/:id',
            element: loadComponent(<Labels />),
          },
          {
            index: true,
            path: 'newProduct/:id?',
            element: loadComponent(<NewProduct />),
          },
          {
            index: true,
            path: 'editProduct/:id',
            element: loadComponent(<NewProduct />),
          },
        ],
      },
      {
        path: 'accounting/:page?',
        children: [
          {
            index: true,
            path: 'landing',
            element: loadComponent(<Accounting />),
          },
          {
            index: true,
            path: 'newAccountMovement',
            element: loadComponent(<AccountMovement />),
          },
        ],
      },
      {
        path: 'counting/:page?/:id?',
        children: [
          {
            index: true,
            path: 'landing',
            element: loadComponent(<Counting />),
          },
          {
            index: true,
            path: 'newCounting',
            element: loadComponent(<NewCounting />),
          },
          {
            index: true,
            path: 'editCounting/:id',
            element: loadComponent(<NewCounting />),
          },
          {
            index: true,
            path: 'viewCounting/:id',
            element: loadComponent(<NewCounting />),
          },
        ],
      },
      {
        path: 'productQuotation/:page?/:id?',
        children: [
          {
            index: true,
            path: 'landing',
            element: loadComponent(<ProductQuotation />),
          },
          {
            index: true,
            path: 'newProductQuotation',
            element: loadComponent(<NewProductQuotation />),
          },
          {
            index: true,
            path: 'editProductQuotation/:id',
            element: loadComponent(<NewProductQuotation />),
          },
          {
            index: true,
            path: 'viewProductQuotation/:id',
            element: loadComponent(<NewProductQuotation />),
          },
        ],
      },
      {
        path: 'inventory/:page?/:id?',
        children: [
          {
            index: true,
            path: 'movement/in',
            element: loadComponent(<Movement />),
          },
          {
            index: true,
            path: 'movement/out',
            element: loadComponent(<Movement />),
          },
          {
            index: true,
            path: 'movement/transfer',
            element: loadComponent(<Movement />),
          },
        ],
      },
      {
        path: 'report/:page?',
        children: [
          {
            index: true,
            path: 'sales',
            element: loadComponent(<SalesHistory />),
          },
          {
            index: true,
            path: 'inventory',
            element: loadComponent(<InventoryMovementHistory />),
          },
          {
            index: true,
            path: 'inventoryInfoV1',
            element: loadComponent(<InventoryInfoV1 />),
          },
          {
            index: true,
            path: 'inventoryInfoV2',
            element: loadComponent(<InventoryInfoV2 />),
          },
          {
            index: true,
            path: 'inventoryReorderReport',
            element: loadComponent(<InventoryReorderReport />),
          },
          {
            index: true,
            path: 'newProductPricing',
            element: loadComponent(<NewProductPricing />),
          },
          {
            index: true,
            path: 'saleConsignment',
            element: loadComponent(<SaleConsignment />),
          },
          {
            index: true,
            path: 'paymentConsigment',
            element: loadComponent(<PaymentConsigment />),
          },
          {
            index: true,
            path: 'salesReport',
            element: loadComponent(<SalesReport />),
          },
          {
            index: true,
            path: 'balanceResult',
            element: loadComponent(<BalanceResult />),
          },
        ],
      },
      {
        path: 'ml/:page?',
        children: [
          {
            index: true,
            path: 'excelSales',
            element: loadComponent(<ExcelSale />),
          },
          {
            index: true,
            path: 'syncSales',
            element: loadComponent(<SyncSales />),
          },
          {
            index: true,
            path: 'syncProducts',
            element: loadComponent(<SyncProducts />),
          },
          {
            index: true,
            path: 'uploadMlProducts',
            element: loadComponent(<MlProducts />),
          },
          {
            index: true,
            path: 'uploadMlFullProducts',
            element: loadComponent(<MlFullProducts />),
          },
          {
            index: true,
            path: 'uploadMlStockFull',
            element: loadComponent(<StockFullSave />),
          },
          {
            index: true,
            path: 'getMlStockFull',
            element: loadComponent(<StockFullGet />),
          },
          {
            index: true,
            path: 'uploadMlStockWithPenalties',
            element: loadComponent(<StockWithPenaltiesSave />),
          },
          {
            index: true,
            path: 'getMlStockWithPenalties',
            element: loadComponent(<StockWithPenaltiesGet />),
          },
          {
            index: true,
            path: 'getMlStockFullRequest',
            element: loadComponent(<MlStockFullRequestGet />),
          },
        ],
      },
      {
        path: 'tn/:page?',
        children: [
          {
            index: true,
            path: 'uploadProductsPrices',
            element: loadComponent(<UploadProductsPrices />),
          },
        ],
      },
      {
        path: 'excel/:page?',
        children: [
          {
            index: true,
            path: 'uploadProducts',
            element: loadComponent(<ExcelUploadProducts />),
          },
        ],
      },
      {
        path: 'configuration/:page?/:subpage?/:id?',
        children: [
          {
            index: true,
            path: 'family',
            element: loadComponent(<Family />),
          },
          {
            index: true,
            path: 'family/new',
            element: loadComponent(<FamilyCreation />),
          },
          {
            index: true,
            path: 'family/edit/:id?',
            element: loadComponent(<FamilyCreation />),
          },
          {
            index: true,
            path: 'subfamily',
            element: loadComponent(<Subfamily />),
          },
          {
            index: true,
            path: 'subfamily/new',
            element: loadComponent(<SubfamilyCreation />),
          },
          {
            index: true,
            path: 'subfamily/edit/:id?',
            element: loadComponent(<SubfamilyCreation />),
          },
          {
            index: true,
            path: 'supplier',
            element: loadComponent(<Supplier />),
          },
          {
            index: true,
            path: 'supplier/new',
            element: loadComponent(<SupplierCreation />),
          },
          {
            index: true,
            path: 'supplier/edit/:id?',
            element: loadComponent(<SupplierCreation />),
          },
          {
            index: true,
            path: 'brand',
            element: loadComponent(<Brand />),
          },
          {
            index: true,
            path: 'brand/new',
            element: loadComponent(<BrandCreation />),
          },
          {
            index: true,
            path: 'brand/edit/:id?',
            element: loadComponent(<BrandCreation />),
          },
          {
            index: true,
            path: 'customer',
            element: loadComponent(<Customer />),
          },
          {
            index: true,
            path: 'customer/new',
            element: loadComponent(<CustomerCreation />),
          },
          {
            index: true,
            path: 'customer/edit/:id?',
            element: loadComponent(<CustomerCreation />),
          },
          {
            index: true,
            path: 'priceType',
            element: loadComponent(<PriceType />),
          },
          {
            index: true,
            path: 'priceType/new',
            element: loadComponent(<PriceTypeCreation />),
          },
          {
            index: true,
            path: 'priceType/edit/:id?',
            element: loadComponent(<PriceTypeCreation />),
          },
          {
            index: true,
            path: 'branch',
            element: loadComponent(<Branch />),
          },
          {
            index: true,
            path: 'branch/new',
            element: loadComponent(<BranchCreation />),
          },
          {
            index: true,
            path: 'branch/edit/:id?',
            element: loadComponent(<BranchCreation />),
          },
          {
            index: true,
            path: 'user',
            element: loadComponent(<User />),
          },
          {
            index: true,
            path: 'user/new',
            element: loadComponent(<UserCreation />),
          },
          {
            index: true,
            path: 'user/edit/:id?',
            element: loadComponent(<UserCreation />),
          },
          {
            index: true,
            path: 'platform',
            element: loadComponent(<Platform />),
          },
        ],
      },
    ],
  },
];

export default routes;
